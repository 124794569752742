<template>
  <div class="ranklist has-nav">
    <div class="logos-wrap">
      <t-image class="logos" :src="require('@/assets/images/logos.png')" />
    </div>
    <t-image
      :src="require('@/assets/images/rank-title.png')"
      class="rank-title"
    />
    <t-image
      :src="require('@/assets/images/theme-pic.png')"
      class="theme-pic"
    />
    <div class="top">
      <div class="tabs">
        <div
          class="tab-item"
          :class="curCategory.category_id == item.category_id ? 'active' : ''"
          v-for="item in categoryList"
          :key="item.category_id"
          @click="toChangeCategory(item)"
        >
          <text>{{ item.short_name }}</text>
        </div>
      </div>
    </div>
    <div class="works-list">
      <div class="panel" v-if="worksList.length == 0"></div>
      <div v-for="item in worksList" :key="item.vote_id">
        <div
          @click="$router.push('/workdetail/' + item.vote_id)"
          class="onlyShow"
          v-if="item.is_only_show == '0'"
        >
          <img src="@/assets/images/shape.png" class="shape" />
          <div class="rank-num">
            <img src="@/assets/images/rank-bg.svg" class="rank-bg" />
          </div>

          <img class="work-cover" mode="aspectFill" :src="item.image" />
          <text class="onlyShow-title">{{ item.title }}</text>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { getCategoryList, rankingList } from "../network/API";
import tools from "../utils/tools";

const categoryList = ref([]);
const worksList = ref([]);
const curCategory = ref({});
const searchKey = ref("");
const lastTap = ref(null);
const emptyData = ref(false);

onMounted(() => {
  getCategorys();
  tools.shareInit();
});

function toDetail(item) {}

function toChangeCategory(item) {
  curCategory.value = item;
  getWorksList();
}
async function getWorksList() {
  let list = await rankingList({
    year: "2024",
    category_id: curCategory.value.category_id,
    subject_id: 1,
  });
  worksList.value = list;
}
async function getCategorys() {
  let data = await getCategoryList("2024");
  categoryList.value = data;
  curCategory.value = curCategory.value.category_id
    ? curCategory.value
    : data[0];
  getWorksList();
}
</script>

<style scoped>
.top {
  padding: 15px;
}

.search-box {
  background: none;
  padding: 15px 15px;
}

.search-box .search {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 35px;
  line-height: 35px;
  border-radius: 35px;
}

.search-box .icon-search {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  margin-right: 10px;
}

.search-box .search input {
  font-size: 15px;
  color: #333;
  flex: 1;
  padding-right: 25px;
}

.search-box .input-font {
  color: #bbb;
}

.search-box .search-clear {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 5px;
  padding: 9px;
  box-sizing: border-box;
  width: 35px;
  height: 35px;
}

.search-box .icon-search {
  width: 16px;
  height: 16px;
  margin-left: 15px;
  margin-right: 10px;
}

.works-list {
  position: relative;
  z-index: 3;
  margin: 0 15px 15px 15px;
}

/* list */
.works-view {
  display: flex;
  flex-direction: column;
  justify-items: center;
  /* padding: 15px; */
}

.work-cover {
  height: 168px;
  /* width: 615px; */
  /* height: 350px; */
  margin-bottom: 15px;
  border-radius: 15px;
  object-fit: cover;
}

.works-view text {
  font-size: 16px;
  font-weight: normal;
  color: #333;
  font-weight: 700;
}

.work-info {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: #f7842c;
}

.work-info span {
  font-size: 15px;
  color: #999999;
}

.work-fav {
  padding: 9px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 175px;
  height: 32px;
  border-radius: 16px;
  background: linear-gradient(110deg, #ffd993 -38%, #ff6d00 61%);
  color: white;
  justify-items: center;
  align-items: center;
  font-size: 15px;
}

.work-fav image {
  width: 16px;
  height: 15px;
  margin-right: 8px;
}

.panel {
  background: #333;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 25px 15px;
}

.onlyShow {
  position: relative;
  border-radius: 15px;
  background: linear-gradient(90deg, #ff7300 0%, #ee2e1b 100%);
  padding: 40px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
}
.shape {
  width: 127px;
  position: absolute;
  right: 0;
  top: 5px;
}
.rank-num {
  position: absolute;
  top: 10px;
  left: 5px;
  color: #fff;
  z-index: 5;
  font-size: 15px;
  font-weight: 700;
  border-radius: 0 0 0px 0;
}
.rank-num .rank-txt {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
}
.rank-num .rank-txt text {
  padding-bottom: 15px;
  font-size: 20px;
  /* color: #bd782a; */
  color: #f7842c;
}
.rank-bg {
  width: 60px;
  height: 60px;
}
.onlyShow-title {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.headline {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;

  color: #f7842c;
}

.icon-headline {
  width: 28px;
  height: 28px;
}

.tips {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 35px;
  background: #ffffcc;
  width: 100%;
  font-size: 12px;
  color: #f7842c;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.icon-tip {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.banner {
  background: linear-gradient(to top, #fd4b28, #f7842c);
  /* background: #FD4B28; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner image {
  width: 710px;
  height: 225px;
}
.rank-title {
  width: 357px;
  margin: 0 auto;
  display: block;
  position: relative;
  z-index: 3;
  padding-top: 40px;
}
</style>
